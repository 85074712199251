<!--
 * @Date: 2021-07-15 18:01:17
 * @LastEditors: Woz
 * @LastEditTime: 2021-09-22 20:11:47
 * @FilePath: \groundle_web\src\views\Contact.vue
-->
<template>
	<div class="scent page hide-scrollbar">
		<vMenu @beforeLeave="clearPage"></vMenu>
		<vLogo></vLogo>
		<div class="header"></div>
		<div class="main">
			<div class="contact voyage">CONTACT</div>
			<br />

			<div class="brand-line">_________</div>
			<br />
			<br />

			<div class="link-holder SourceHanSerifSemiBold">

				<div class="link-unit">
					<!-- <div>微信公众号</div> -->
					<div>
						<img src="../assets/icon_wechat.png" class="icon" />
					</div>
					<div>groundle</div>
				</div>

	<div class="link-unit link" @click="goto('https://weibo.com/groundle')">
					<!-- <div>微博</div> -->
					<img src="../assets/icon_weibo.png" class="icon" />
					<div>groundle_official</div>
				</div>
				


				<div
					class="link-unit link"
					@click="
						goto(
							'https://www.xiaohongshu.com/user/profile/600e76ab000000000101de4d?xhsshare=CopyLink&appuid=600e76ab000000000101de4d&apptime=1630925656'
						)
					"
				>
					<!-- <div>小红书</div> -->
					<img src="../assets/icon_red.png" class="icon" />
					<div>groundle</div>
				</div>

			
				<div class="link-unit">
					<!-- <div>微信公众号</div> -->
					<div>
						<img src="../assets/icon_tmall.png" class="icon" />
					</div>
					<div>groundle</div>
				</div>

				<div
					class="link-unit link"
					@click="goto('https://www.instagram.com/groundle_official/')"
				>
					<!-- <div>instagram</div> -->
					<img src="../assets/icon_ins.png" class="icon" />
					<div>groundle_official</div>
				</div>

				<div class="link-unit link">
					<!-- <div class="SourceHanSerifSemiBold">业务联系人</div> -->

					<img src="../assets/icon_email.png" class="icon" />
					<div class="SourceHanSerifSemiBold">
						<a href="mailto:info@groundle.com">info@groundle.com</a>
					</div>

					<!-- <div class="SourceHanSerifSemiBold">19512162362</div> -->
				</div>
			</div>

			<div class="qr-holder SourceHanSerifSemiBold">
				<!-- <div class="qr-unit">
					<div>
						<div>微信公众号</div>
						<div>groundle</div>
					</div>

					<img src="../assets/qr_wechat.jpg" class="qr-code" />
				</div>
				<div class="qr-unit">
					<div>
						<div>微博</div>
						<div>groundle_official</div>
					</div>

					<img src="../assets/qr_weibo.jpg" class="qr-code" />
				</div>
				<div class="qr-unit">
					<div>
						<div>小红书</div>
						<div>groundle</div>
					</div>

					<img src="../assets/qr_redbook.jpg" class="qr-code" />
				</div> -->
			</div>
		</div>

		<vFooter></vFooter>
	</div>
</template>
<style scoped>
	.page {
		overflow-y: scroll;
	}
	.header {
		height: 489px;
		width: 100%;
		background: url("../assets/bg_header.jpg");
		background-size: cover;
		background-position: top center;
	}
	.link-unit {
		margin-top: 49px;
	}
	.icon {
		width: 48px;
	}
	a {
		/* text-decoration: none; */
		color: #4C3526;
	}
	.main {
		color: #4C3526;
		position: relative;
		width: 100%;
		min-height: 928px;
		background: url("../assets/bg_contact.jpg");
		background-size: cover;
		background-position: top center;
		margin-top: -1px;
		font-size: 24px;
		line-height: 41px;
	}
	.contact {
		font-size: 34px;
		padding-top: 148px;
	}
	.qr-holder {
		font-size: 24px;
		width: 900px;
		margin: auto;
		margin-top: 100px;
		display: flex;
		justify-content: space-between;
		flex-direction: row;
	}
	.qr-unit {
		line-height: 42px;
	}
	.qr-code {
		width: 100px;
		margin-top: 30px;
	}
	.link {
		cursor: pointer;
	}
</style>
<script>
	import vLogo from "../components/floatLogo.vue";
	import vMenu from "../components/menu.vue";
	import vFooter from "../components/footer.vue";
	export default {
		name: "Contact",
		data() {
			return {};
		},
		methods: {
			clearPage(val, callback) {
				callback("ok");
			},
			goto(path) {
				location.href = path;
			},
		},
		components: { vLogo, vMenu, vFooter },
		computed: {},
		mounted() {
			this.$ba.trackPageview(this.$route.path);
		},
	};
</script>
