<!--
 * @Date: 2021-07-15 12:47:28
 * @LastEditors: Woz
 * @LastEditTime: 2021-09-15 17:51:06
 * @FilePath: \groundle_web\src\views\About.vue
--> 

<template>
	<div
		class="scent page hide-scrollbar"
		v-track-pageview="{ pageURL: '/About' }"
	>
		<vMenu @beforeLeave="clearPage"></vMenu>
		<vLogo></vLogo>
		<div class="header"></div>
		<div class="main SourceHanSerifSemiBold">
			<div class="brand voyage">BRAND STORY</div>
			<br />

			<div class="brand-line voyage">_________</div>
			<br />
			<div>「groundle」具有浪漫情怀的自然主义香氛品牌</div>
			<div>取意于 ground 和 candle 的结合</div>

			<br />
			<div>品牌将精神理念立足于大地万物</div>
			<div>用独特的叙事视角将自然香气融入呼吸之间</div>
			<div>在自然风物主导的氛围之下</div>
			<div>groundle 以地域性特色为线索</div>
			<div>
				搜寻自然辽远与不甚细微的一切，用从大地上生长出来的香气，放松情绪。
			</div>

			<br />

			<div>脚踩在大地上</div>
			<div>groundle 不急不缓地相信，借着自然中流动的能量</div>
			<div>身心可以在生活一隅中妥善安放。</div>
		</div>

		<vFooter></vFooter>
	</div>
</template>
<style scoped>
	.page {
		overflow-y: scroll;
	}
	.header {
		height: 489px;
		width: 100%;
		background: url("../assets/bg_header.jpg");
		background-size: cover;
		background-position: top center;
	}

	.main {
		color: #4c3526;
		position: relative;
		width: 100%;
		min-height: 1234px;
		background: url("../assets/bg_about.jpg");
		background-position: top center;
		margin-top: -1px;
		font-size: 24px;
		line-height: 41px;
	}

	.brand {
		font-size: 34px;
		padding-top: 137px;
	}
</style>
<script>
	import vLogo from "../components/floatLogo.vue";
	import vMenu from "../components/menu.vue";
	import vFooter from "../components/footer.vue";
	export default {
		name: "About",
		data() {
			return {};
		},
		methods: {
			clearPage(val, callback) {
				callback("ok");
			},
		},
		components: { vLogo, vMenu, vFooter },
		computed: {},
		mounted() {
			this.$ba.trackPageview(this.$route.path);
		},
	};
</script>
