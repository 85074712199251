
<template>
	<div class="home page" @mousemove="moveMap">
		<vMenu @beforeLeave="clearPage"></vMenu>
		<vLogo></vLogo>

		<div class="world-wrapper sourceHanSerif">
			<!-- <div
				style="
					position: absolute;
					z-index: 999;
					left: 0;
					top: 0;
					width: 200px;
					height: 50px;
					background: black;
					color: white;
				"
			>
				{{ moving }}
			</div> -->
			<div
				class="move-holder"
				:class="{ left: moving == 'left', right: moving == 'right' }"
				ref="mapBase"
			>
				<div class="world-rolling" ref="rollingMap">
					<div class="map map-real" ref="realMap"></div>
					<!-- <img src="../assets/world_map.png" ref="realMap" class="map map-real" /> -->
					<!-- <img src="../assets/world_map_cover.png" ref="fakeMap" class="map" /> -->
					<div class="map map-fake" ref="fakeMap"></div>

					<div
						ref="icons"
						class="icon-unit"
						v-for="i in iconList"
						v-bind:key="i.name"
						:style="i.position"
						@click="goto(i.path)"
						@mouseover="playSound(i)"
						@mouseout="closeSound(i)"
					>
						<div class="icon-hover">
							<div class="icon-blur icon-blur-1" :style="i.color"></div>
							<div class="icon-blur icon-blur-2" :style="i.color"></div>
							<div class="icon-blur icon-blur-3" :style="i.color"></div>
							<img class="icon-pic" :src="i.img" />
						</div>
						<div class="icon-init">
							<div class="icon-init-core"></div>
							<div class="icon-init-blur"></div>
							<div class="icon-init-blur2"></div>
						</div>
					</div>
				</div>
			</div>

			<div class="world-word-wrapper" ref="lrcHolder" v-if="!wordsDone">
				<div ref="lrcWords">
					<div
						class="words"
						v-for="(word, index) in lrcs"
						v-bind:key="index"
						ref="lrc"
					>
						{{ word }}
					</div>
				</div>

				<div ref="lrcFinal" class="words final">{{ lrcFinal }}</div>
			</div>
		</div>
	</div>
</template>
<style scoped>
	.world-wrapper {
		height: 100%;
	}

	.home {
		overflow: hidden;
	}
	@keyframes map-roll {
		0% {
			-webkit-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0);
		}

		100% {
			-webkit-transform: translate3d(-172vh, 0, 0);
			transform: translate3d(-172vh, 0, 0);
		}
	}
	.world-word-wrapper {
		width: 100%;
		text-align: center;
		font-size: 24px;
		top: 50%;
		position: absolute;
		color: black;
		font-size: 1.5rem;
		line-height: 2.5rem;
		/* display: none; */
	}

	.world-title-wrapper {
		width: 100%;
		text-align: center;
		font-size: 24px;
		top: 30%;
		position: absolute;
		/* display: none; */
		color: black;
		font-size: 1.7rem;
		line-height: 2.5rem;
	}

	.world-rolling {
		width: 1500vh;
		height: 100%;
		background: #eeeeee;
		position: absolute;
		left: 0;
	}
	.map-real {
		opacity: 0;
		width: 1500vh;
		background-size: auto 100%;
		background-image: url("../assets/world_map.png");
	}
	.map-fake {
		width: 1500vh;
		background-size: auto 100%;
		background-image: url("../assets/world_map_cover.png");
	}

	.map {
		position: absolute;
		left: 0;
		height: 100%;
	}
	.words {
		opacity: 0.5;
		font-size: 21px;
	}
	.final {
		font-size: 27.5px;
		margin: 2rem;
		opacity: 0;
	}
	.icon-unit {
		position: absolute;
		width: 80px;
		height: 80px;
		opacity: 0;
		transform: scale(0.3);
		cursor: pointer;
		display: flex;
		text-align: center;
	}
	.icon-pic {
		cursor: pointer;
		height: 100%;
		position: relative;
		z-index: 5;
		margin: auto;
	}
	.icon-hover {
		opacity: 0;
		position: absolute;
		width: 100%;
		height: 100%;
		transition: 500ms all;
		display: flex;
		justify-content: center;
	}
	.icon-init {
		width: 1.5rem;
		margin: auto;
		height: 1.5rem;
	}
	.icon-unit:hover .icon-init {
		opacity: 0;
		transition: 500ms all;
	}
	.icon-unit:hover .icon-hover {
		opacity: 1;
	}

	.icon-unit:hover .icon-blur-1 {
		-webkit-transform: scale(1.18);
		transform: scale(1.18);
	}

	.icon-unit:hover .icon-blur-2 {
		-webkit-transform: scale(1.75);
		transform: scale(1.75);
	}

	.icon-unit:hover .icon-blur-3 {
		-webkit-transform: scale(2.76);
		transform: scale(2.76);
	}

	.icon-blur {
		width: 100%;
		height: 100%;
		position: absolute;
		/* margin: 0 5px; */
		border-radius: 50%;
		transition: 0.5s all ease-out;
	}
	.icon-blur-1 {
		opacity: 0.4;
	}
	.icon-blur-2 {
		opacity: 0.4;
	}
	.icon-blur-3 {
		opacity: 0.4;
	}
	.icon-init-blur {
		animation: icon-blur infinite 3s ease-in;
		width: 19px;
		height: 19px;
		position: absolute;
		z-index: 4;
		opacity: 0.2;

		border-radius: 50%;
		background: #5a2325;
	}

	.icon-init-blur2 {
		animation: icon-blur2 infinite 3s ease-in;
		width: 19px;
		height: 19px;
		position: absolute;
		z-index: 4;
		opacity: 0.2;
		/* -webkit-transform: scale(2.5); */
		/* transform: scale(2.5); */
		border-radius: 50%;
		background: #5a2325;
	}

	.icon-init-core {
		position: absolute;
		z-index: 5;
		background: #493c33;
		width: 19px;
		height: 19px;
		border-radius: 50%;

		transition: 500ms all;
	}

	@keyframes icon-blur {
		50% {
			-webkit-transform: scale(2.5);
			transform: scale(2.5);
		}
	}
	@keyframes icon-blur2 {
		50% {
			-webkit-transform: scale(4);
			transform: scale(4);
		}
	}

	.left {
		left: 5vw !important;
		/* transform: scale(1.01); */
	}
	.right {
		left: -5vw !important;
		/* transform: scale(1.01); */
	}
	.move-holder {
		left: 0;
		position: absolute;
		height: 100%;
		width: 100%;
		transition: all 4000ms cubic-bezier(0.2, 0.72, 0.28, 1);
	}
</style>
<script>
	import { Howl } from "howler";
	import Velocity from "velocity-animate";
	import vLogo from "../components/floatLogo.vue";
	import vMenu from "../components/menu.vue";
	import HomeVue from "./Home.vue";
	export default {
		name: "Map",
		data() {
			return {
				moving: false,
				mousemoving: 0,
				playing: false,
				wordsDone: false,
				iconList: [
					{
						name: "aomori",
						position: { left: "259vh", top: "37vh" },
						color: { background: "white" },
						img: require("../assets/icon_Aomori.png"),
						path: "item/moss",
						sound: require("../assets/flash/moss.mp3"),
					},
					{
						name: "ammon",
						position: { left: "205vh", top: "48vh" },
						color: { background: "#5a2325" },
						img: require("../assets/icon_Amman.png"),
						path: "item/deadsea",
						sound: require("../assets/flash/deadsea.mp3"),
					},
					{
						name: "carribean",
						position: { left: "328vh", top: "57vh" },
						color: { background: "white" },
						img: require("../assets/icon_Carribean.png"),
						path: "item/cotton",
						sound: require("../assets/flash/cotton.mp3"),
					},
					{
						name: "bahamas",
						position: { left: "325vh", top: "51vh" },
						color: { background: "white" },
						img: require("../assets/icon_Bahamas.png"),
						path: "item/pinksalt",
						sound: require("../assets/flash/pinksalt.mp3"),
					},

					{
						name: "nile",
						position: { left: "201vh", top: "48vh" },
						color: { background: "white" },
						img: require("../assets/icon_Nile.png"),
						path: "item/mint",
						sound: require("../assets/flash/mint.mp3"),
					},

					{
						name: "granada",
						position: { left: "185vh", top: "41vh" },
						color: { background: "white" },
						img: require("../assets/icon_Granada.png"),
						path: "item/olive",
						sound: require("../assets/flash/olive.mp3"),
					},
					{
						name: "ninfa",
						position: { left: "195vh", top: "40vh" },
						color: { background: "white" },
						img: require("../assets/icon_Ninfa.png"),
						path: "item/soil",
						sound: require("../assets/flash/soil.mp3"),
					},

					{
						name: "mehmeh",
						position: { left: "191vh", top: "37vh" },
						color: { background: "white" },
						img: require("../assets/icon_Valais.png"),
						path: "item/mehmeh",
						sound: require("../assets/flash/mehmeh.mp3"),
					},

					{
						name: "leather",
						position: { left: "188vh", top: "30.5vh" },
						color: { background: "white" },
						img: require("../assets/icon_SavileRow.png"),
						path: "item/leather",
						sound: require("../assets/flash/leather.mp3"),
					},
				],
				showMap: false,
				lrcs: [
					"我们对世界的大部分感觉",
					"总会跟声音和气味相连",
					"一个地点 一种气味",
					"groundle打造了一张充满想象力的嗅觉感官地图",
					"行走中遇见的气味与声音",
					"是我们希望记录下来的感性片刻",
				],
				lrcFinal: "在groundle的世界里 听见气味",
			};
		},
		methods: {
			playSound(i) {
				// console.log(i);
				if (this.playing) {
					//still playing？
				} else {
					let that = this;
					this.sound = null;

					this.sound = new Howl({
						src: [i.sound],
						autoplay: false,
						loop: false,
						onend: function () {
							// console.log("Finished!");
							this.playing = false;
						},
					});
					// console.log("sound");
					this.sound.play();
					this.playing = true;
				}
			},
			closeSound(i) {
				// console.log("sound over");
				this.sound.fade(1, 0, 200);
				this.playing = false;
				this.sound = null;
			},

			goto(name) {
				console.log(name);
				if (this.$route.name == name) {
					//
				} else {
					this.$router.push(name);
				}
			},
			initRolling() {
				// let that = this;
				// const realMap= this.$refs.realMap;
				const rollingMap = this.$refs.rollingMap;

				this.rolling = Velocity(
					rollingMap,
					{ translateX: "-172vh" },
					{
						duration: 10000,
						// loop: true,
						easing: "easeIn",
						begin: function (elements) {
							// console.log(elements);
						},
						// complete: function () {
						// 	that.initRolling();
						// },
					}
				);
			},
			doTransform() {
				this.initRolling();
				let list = this.$refs.lrc;
				let that = this;

				let holder = this.$refs.lrcHolder;

				list.forEach((r, i) => {
					Velocity(
						holder,
						{ translateY: "-=40px" },
						{
							duration: 600,
							delay: i == 0 ? 0 : 500,
							begin: function () {
								Velocity(r, { opacity: 1 }, { duration: 500 });
								if (i > 0) {
									Velocity(list[i - 1], { opacity: 0 }, { duration: 500 });
								}
							},
							complete: function () {
								// console.log("complete");
							},
						}
					);

					// Velocity(
					// 	r,
					// 	{ translateY: "-30px", opacity: 1 },
					// 	{ duration: 1000, delay: i * 1000 + 1000 }
					// );
					// Velocity(r, { opacity: 0.3 }, { duration: 600, delay: 200 });
				});

				Velocity(
					this.$refs.lrcWords,
					{ translateY: "-40px", opacity: 0 },
					{ duration: 1000, delay: (list.length + 7) * 500 }
				);

				Velocity(
					this.$refs.lrcFinal,
					{ translateY: "-40px", opacity: 1 },
					{
						duration: 1000,
						delay: (list.length + 8) * 500,
						complete: function () {
							Velocity(
								that.$refs.lrcFinal,
								{ translateY: "-60px", opacity: 0 },
								{
									duration: 1200,
									delay: 2000,
									complete: function () {
										that.showMap = true;
									},
								}
							);
						},
					}
				);
			},
			showRealMap() {
				let that = this;

				Velocity(
					that.$refs.fakeMap,
					{ opacity: 0 },
					{
						duration: 800,
					}
				);
				Velocity(
					that.$refs.realMap,
					{ opacity: 1 },
					{
						duration: 800,
					}
				);
				this.wordsDone = true;
				this.showIcons();
			},
			showIcons() {
				let list = this.$refs.icons;

				list.forEach((r, i) => {
					Velocity(
						r,
						{ scaleX: 1, opacity: 1 },
						{ duration: 800, delay: i * 400 + 1200 }
					);
				});
			},
			moveMap(e) {
				let w = window.innerWidth;

				let that = this;
				const deltaW = 0.1;

				// const map = this.$refs.mapBase;

				if (this.showMap) {
					//在右侧
					if (e.clientX > w * (1 - deltaW)) {
						// console.log("come in right");
						that.moving = "right";

						// Velocity(
						// 	map,
						// 	{ left: "-5vw" },
						// 	{
						// 		duration: 1000,
						// 		easing: "easeOutCirc",
						// 		complete: function () {
						// 			console.log("done in right");
						// 		},
						// 	}
						// );
					} else if (e.clientX < w * deltaW) {
						// 在左侧

						that.moving = "left";
						// console.log("come in left");
						// Velocity(
						// 	map,
						// 	{ left: "5vw" },
						// 	{
						// 		duration: 1000,
						// 		easing: "easeOutCirc",
						// 		complete: function () {
						// 			console.log("done in left");
						// 		},
						// 	}
						// );
					} else {
						// console.log("reset");
						that.moving = "";

						// // Velocity(map, "stop");
						// Velocity(map, "reverse", { duration: 1000 });
					}
				}
			},

			moveMap_(e) {
				// console.log(e);
				// console.log(window.innerWidth);
				let w = window.innerWidth;
				let that = this;
				const deltaW = 0.3;
				const alpha = 3;

				if (this.showMap) {
					if (e.clientX > w * (1 - deltaW) || e.clientX < w * deltaW) {
						let delta;
						if (e.clientX > w * (1 - deltaW)) {
							delta = e.clientX - w * (1 - deltaW);
						} else {
							delta = -(w * deltaW - e.clientX);
						}
						// console.log("delta", delta);

						let shif =
							(delta / (deltaW * w * (1 / alpha))) *
							(Math.abs(delta) / (deltaW * w * (1 / alpha)));
						// shif = 0;

						// console.log(shif);
						that.mousemoving = "translateX(" + -shif + "vw)";
						// console.log(that.mousemoving);
					}
				}
			},

			clearPage(val, callback) {
				callback("ok");
			},
		},
		watch: {
			showMap: function () {
				// console.log(newValue);

				this.showRealMap();
			},
		},
		components: { vLogo, vMenu },
		computed: {},
		mounted() {
			// console.log(this.$refs);
			// this.initRolling();
			// this.showRealMap();
			this.doTransform();
			// this.showRealMap();
			this.$ba.trackPageview(this.$route.path);
		},
	};
</script>
