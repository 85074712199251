/*
 * @Date: 2021-07-21 15:49:44
 * @LastEditors: Woz
 * @LastEditTime: 2021-09-15 20:32:09
 * @FilePath: \groundle_web\src\contents\items.js
 */

const items = {
    moss: {
        index: 0,
        location: 'Aomori, Japan',
        name: "moss",
        img_header: require("../assets/products/moss/header.jpg"),
        sound: require("../assets/mp3/moss.mp3"),
        subtitle: "苔藓",

        img_p1: require('../assets/products/moss/kv.png'),
        img_t1: require('../assets/products/moss/t1.png'),

        img_t2: require('../assets/products/moss/t2.png'),
        img_l2: [
            require('../assets/products/moss/list_1.png'),
            require('../assets/products/moss/list_2.png'),
        ],

        img_list: [
            require("../assets/products/moss/s1.png"),
            require("../assets/products/moss/s2.png"),
            require("../assets/products/moss/s3.png"),
            require("../assets/products/moss/s4.png")
        ],
        link: ['/item/deadsea', '/item/cotton', '/item/deadsea', '/item/cotton',],
        img_bg: require("../assets/products/moss/bg.jpg")
    },
    deadsea: {
        index: 1,
        location: 'Amman, West Asia',
        name: "deadsea",
        img_header: require("../assets/products/deadsea/header.jpg"),
        sound: require("../assets/mp3/deadsea.mp3"),

        title: "The Dead Sea In Amman ",
        subtitle: "死海",

        img_p1: require('../assets/products/deadsea/kv.png'),
        img_t1: require('../assets/products/deadsea/t1.png'),

        img_t2: require('../assets/products/deadsea/t2.png'),
        img_l2: [
            require('../assets/products/deadsea/list_1.png'),
            require('../assets/products/deadsea/list_2.png'),
        ],

        img_list: [
            require("../assets/products/deadsea/s1.png"),
            require("../assets/products/deadsea/s2.png"),
            require("../assets/products/deadsea/s3.png"),
            require("../assets/products/deadsea/s4.png")
        ],
        link: ['/item/moss', '/item/cotton', '/item/moss', '/item/cotton',],
        img_bg: require("../assets/products/deadsea/bg.jpg")
    },
    cotton: {
        index: 2,
        location: 'Caribbean, South America',
        name: "cotton",
        img_header: require("../assets/products/cotton/header.jpg"),
        sound: require("../assets/mp3/cotton.mp3"),

        title: "The Cotton In Carribean",
        subtitle: "海绵岛",
        img_p1: require('../assets/products/cotton/kv.png'),
        img_t1: require('../assets/products/cotton/t1.png'),

        img_t2: require('../assets/products/cotton/t2.png'),
        img_l2: [
            require('../assets/products/cotton/list_1.png'),
            require('../assets/products/cotton/list_2.png'),
        ],


        img_list: [
            require("../assets/products/cotton/s1.png"),
            require("../assets/products/cotton/s2.png"),
            require("../assets/products/cotton/s3.png"),
            require("../assets/products/cotton/s4.png")
        ],
        link: ['/item/deadsea', '/item/moss', '/item/deadsea', '/item/moss',],
        img_bg: require("../assets/products/cotton/bg.jpg")
    },
    olive: {
        index: 3,
        location: 'Granada, Spain',
        name: "olive",
        img_header: require("../assets/products/olive/header.jpg"),
        sound: require("../assets/mp3/olive.mp3"),

        title: "The Olive In Granada",
        subtitle: "橄榄",
        img_p1: require('../assets/products/olive/kv.png'),
        img_t1: require('../assets/products/olive/t1.png'),

        img_t2: require('../assets/products/olive/t2.png'),
        img_l2: [
            require('../assets/products/olive/list_1.png'),
            require('../assets/products/olive/list_2.png'),
        ],


        img_list: [
            require("../assets/products/olive/s1.png"),
            require("../assets/products/olive/s2.png"),
            require("../assets/products/olive/s3.png"),
            require("../assets/products/olive/s4.png")
        ],
        link: ['/item/soil', '/item/cotton', '/item/soil', '/item/cotton',],
        img_bg: require("../assets/products/olive/bg.jpg")
    },

    soil: {
        index: 4,
        location: 'Ninfa, Italy',
        name: "soil",
        img_header: require("../assets/products/soil/header.jpg"),
        sound: require("../assets/mp3/soil.mp3"),

        title: "The Soil In Ninfa",
        subtitle: "泥土蔷薇",
        img_p1: require('../assets/products/soil/kv.png'),
        img_t1: require('../assets/products/soil/t1.png'),

        img_t2: require('../assets/products/soil/t2.png'),
        img_l2: [
            require('../assets/products/soil/list_1.png'),
            require('../assets/products/soil/list_2.png'),
        ],


        img_list: [
            require("../assets/products/soil/s1.png"),
            require("../assets/products/soil/s2.png"),
            require("../assets/products/soil/s3.png"),
            require("../assets/products/soil/s4.png")
        ],
        link: ['/item/olive', '/item/cotton', '/item/olive', '/item/cotton',],
        img_bg: require("../assets/products/soil/bg.jpg")
    },
    mehmeh: {
        index: 5,
        location: 'Valais , Switzerland',
        name: "mehmeh",
        img_header: require("../assets/products/mehmeh/header.jpg"),
        sound: require("../assets/mp3/mehmeh.mp3"),

        title: "\"meh meh\" in Valais",
        subtitle: "咩咩",
        img_p1: require('../assets/products/mehmeh/kv.png'),
        img_t1: require('../assets/products/mehmeh/t1.png'),

        img_t2: require('../assets/products/mehmeh/t2.png'),
        img_l2: [
            require('../assets/products/mehmeh/list_1.png'),
            require('../assets/products/mehmeh/list_2.png'),
            require('../assets/products/mehmeh/list_3.png'),
            require('../assets/products/mehmeh/list_4.png'),
        ],


        img_list: [
            require("../assets/products/mehmeh/s1.png"),
            require("../assets/products/mehmeh/s2.png"),
            require("../assets/products/mehmeh/s3.png"),
            require("../assets/products/mehmeh/s4.png")
        ],
        link: ['/item/soil', '/item/olive', '/item/soil', '/item/olive',],
        img_bg: require("../assets/products/mehmeh/bg.jpg")
    },




    pinksalt: {
        index: 6,
        location: 'Bahamas',
        name: "pinksalt",
        img_header: require("../assets/products/pinksalt/header.jpg"),
        sound: require("../assets/mp3/pinksalt.mp3"),

        title: "\"meh meh\" in Valais",
        subtitle: "咩咩",
        img_p1: require('../assets/products/pinksalt/kv.png'),
        img_t1: require('../assets/products/pinksalt/t1.png'),

        img_t2: require('../assets/products/pinksalt/t2.png'),
        img_l2: [
            require('../assets/products/pinksalt/list_1.png'),
            require('../assets/products/pinksalt/list_2.png'),

        ],


        img_list: [
            require("../assets/products/pinksalt/s1.png"),
            require("../assets/products/pinksalt/s2.png"),
            require("../assets/products/pinksalt/s3.png"),
            require("../assets/products/pinksalt/s4.png")
        ],
        link: ['/item/soil', '/item/cotton', '/item/soil', '/item/cotton',],
        img_bg: require("../assets/products/pinksalt/bg.jpg")
    }

    ,
    mint: {
        index: 7,
        location: 'Nile',
        name: "mint",
        img_header: require("../assets/products/mint/header.jpg"),
        sound: require("../assets/mp3/mint.mp3"),

        title: "the mint in nile",
        subtitle: "薄荷",
        img_p1: require('../assets/products/mint/kv.png'),
        img_t1: require('../assets/products/mint/t1.png'),

        img_t2: require('../assets/products/mint/t2.png'),
        img_l2: [
            require('../assets/products/mint/list_1.png'),
            require('../assets/products/mint/list_2.png'),
            require('../assets/products/mint/list_3.png'),
        ],


        img_list: [
            require("../assets/products/mint/s1.png"),
            require("../assets/products/mint/s2.png"),
            require("../assets/products/mint/s3.png"),
            require("../assets/products/mint/s4.png")
        ],
        link: ['/item/pinksalt', '/item/cotton', '/item/pinksalt', '/item/cotton',],
        img_bg: require("../assets/products/mint/bg.jpg")
    }
    ,
    leather: {
        fixHeightClass: 'fix-height-leather',
        index: 8,
        location: 'Savile Row',
        name: "leather",
        img_header: require("../assets/products/leather/header.jpg"),
        sound: require("../assets/mp3/leather.mp3"),

        title: "the leather in nile",
        subtitle: "薄荷",
        img_p1: require('../assets/products/leather/kv.png'),
        img_t1: require('../assets/products/leather/t1.png'),

        img_t2: require('../assets/products/leather/t2.png'),
        img_l2: [
            require('../assets/products/leather/list_1.png'),
            require('../assets/products/leather/list_2.png'),
            require('../assets/products/leather/list_3.png'),
        ],


        img_list: [
            require("../assets/products/leather/s1.png"),
            require("../assets/products/leather/s2.png"),
            require("../assets/products/leather/s3.png"),
            require("../assets/products/leather/s4.png")
        ],
        link: ['/item/pinksalt', '/item/mint', '/item/pinksalt', '/item/mint',],
        img_bg: require("../assets/products/leather/bg.jpg")
    }

}
export default
    items