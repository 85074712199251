<!--
 * @Date: 2021-07-15 14:28:20
 * @LastEditors: Woz
 * @LastEditTime: 2021-09-18 14:47:13
 * @FilePath: \groundle_web\src\views\Item.vue
-->
 
<template>
	<transition name="fade">
		<full-page
			ref="fullpage"
			:options="options"
			id="fullpage"
			:skip-init="true"
		>
			<div class="section">
				<vMenu @beforeLeave="clearPage"></vMenu>
				<vLogo></vLogo>
				<div
					class="header"
					v-bind:style="{
						'background-image': 'url(' + item.img_header + ')',
					}"
					ref="header"
				>
					<img
						class="float-left-arrow icon-arrow"
						src="../assets/icon_arrow.png"
						@click="next(-1)"
					/>
					<img
						class="float-right-arrow icon-arrow"
						src="../assets/icon_arrow.png"
						@click="next(1)"
					/>
					<!-- <img
						@click="showDetail"
						class="float-bottom-arrow icon-arrow"
						src="../assets/icon_arrow.png"
					/> -->

					<div class="float-bottom-arrow SourceHanSansRegular">SCROLL DOWN</div>

					<div class="player holder">
						<div class="player-holder">
							<div class="val-icon">
								<img src="../assets/icon_vol.png" />
							</div>
							<div class="player-progress-holder">
								<div class="player-bar"></div>
								<div
									class="player-seeker"
									:style="{ left: progress * 100 + '%' }"
								></div>
							</div>

							<div class="pause-holder">
								<img
									v-if="playing"
									src="../assets/btn_pause.png"
									@click="pause"
									class="btn-pause"
								/>
								<img
									v-if="!playing"
									src="../assets/btn_play.png"
									@click="start"
									class="btn-pause"
								/>
							</div>

							<div class="duration">{{ seek }}</div>

							<div class="comment">{{ item.location }}</div>
						</div>
					</div>
				</div>
			</div>

			<div class="section">
				<div class="main">
					<div
						class="p-main"
						v-bind:class="item.fixHeightClass"
						v-bind:style="{
							'background-image': 'url(' + item.img_bg + ')',
						}"
					>
						<img :src="item.img_bg" class="p-bg" />

						<div class="p-content">
							<div class="p-row row1">
								<img class="col-img row1-l" :src="item.img_p1" />

								<img class="col-img row1-r" :src="item.img_t1" />
							</div>

							<div class="p-row row2">
								<img class="col-img row2-l" :src="item.img_t2" />
								<swiper
									class="row2-r"
									ref="itemSwiper"
									:options="swiperOptions"
								>
									<swiper-slide v-for="i in item.img_l2" v-bind:key="i">
										<div class="swiper-slide">
											<img class="swiper-img" :src="i" /></div
									></swiper-slide>

									<div class="swiper-pagination" slot="pagination"></div>
								</swiper>
							</div>
						</div>
					</div>
					<div class="btn-more voyage">
						More Product
						<span class="mini-more sourceHanSerif"
							>点击下方获取更多产品信息</span
						>
					</div>
					<div class="p-swiper">
						<div
							class="p-swiper-pic"
							v-for="(img, index) in item.img_list"
							v-bind:key="index"
						>
							<img :src="img" @click="goto(item.link[index])" />
						</div>
					</div>
				</div>

				<vFooter></vFooter>
			</div>
		</full-page>
	</transition>
</template>
<style scoped>
	.btn-more {
		height: 120px;
		font-size: 29px;
		margin-left: 64px;
		text-align: left;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		align-content: center;
		justify-content: flex-start;
		align-items: center;
		color: #4c3526;
	}
	.float-bottom-arrow {
		position: absolute;
		bottom: 30px;
		left: 0;
		width: 100%;
		text-align: center;
		color: white;
		font-size: 12px;
		/* transform: rotate(-90deg); */
	}
	.mini-more {
		font-size: 25px;
		margin-left: 22px;
	}
	.row1 {
		height: 863px;
	}
	.row2 {
		height: 1063px;
		margin-top: 231px;
	}
	.row1-l {
		width: 861px;
		float: left;
	}
	.row1-r {
		width: 1059px;
		float: right;
	}

	.row2-l {
		width: 861px;
		float: left;
	}
	.row2-r {
		width: 1059px;
		float: right;
		transform: translate3d(0, 0, 0);
		overflow: hidden;
	}
	.row2-r .swiper-slide {
		transform: translate3d(0, 0, 0);
	}

	.p-content {
		/* background: yellow; */
		padding-top: 224px;
	}
	.swiper-img {
		width: 100%;
	}

	.p-row {
		width: 100%;
		position: relative;
	}
	.header {
		height: 100%;
		width: 100%;

		position: relative;
		background-size: cover;
		background-position: center center;
	}

	.main {
		background: #f6f6f6;
		min-height: 100%;
	}

	.p-main {
		width: 100%;
		/* min-height: 3708px; */
		min-height: 3508px;
		position: relative;
		color: #493c33;
	}


	.fix-height-leather{
		height:3825px;
	}
	.p-bg {
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}

	.p-swiper {
		width: 100%;
		height: 25vw;
		display: flex;
	}
	.p-swiper-pic img {
		width: 100%;
		cursor: pointer;
	}

	.icon-arrow {
		max-width: 15px;
		max-height: 15px;
	}

	.float-left-arrow {
		position: absolute;
		top: 55vh;
		left: 0;
		padding: 40px;
	}
	.float-right-arrow {
		position: absolute;
		top: 55vh;
		right: 0;
		padding: 40px;
		transform: rotate(180deg);
	}
	.player {
		top: 80%;
		transition: 500ms linear;
	}
	.player-holder {
		width: 23vw;
		height: 2rem;
		/* background: blue; */
		margin: auto;
		position: relative;
		display: flex;
		height: 2rem;
		justify-content: center;
		align-items: center;
	}

	.comment {
		color: white;
		font-size: 16px;
		margin-top: 70px;
	}

	.val-icon {
		height: 24px;
		position: absolute;
		left: -30px;
		margin-left: -13px;
		vertical-align: middle;
	}
	.val-icon img {
		height: 24px;
	}
	.player-progress-bar {
		width: 100%;
	}

	.player-bar {
		width: 100%;
		height: 2px;
		background: white;
		position: absolute;
		/* top: 10px; */
		left: 0;
		opacity: 0.5;
	}
	.player-seeker {
		width: 10px;
		height: 10px;
		background: white;
		border-radius: 50%;
		position: absolute;
		margin-top: -4px;
		transition: left linear 0.3s;
	}
	.duration {
		position: absolute;
		right: -120px;
		font-size: 14px;
		color: white;
	}
	.btn-pause {
		height: 20px;
		/* padding: 2vw; */
		margin-left: -15px;
		vertical-align: middle;
		padding: 10px;
		/* background: yellow; */
		cursor: pointer;
	}
	.pause-holder {
		/* margin-left: -1rem; */
		position: absolute;
		right: -40px;
	}
	li {
		list-style-type: none;
		font-size: 1rem;
	}

	.swiper-container {
		--swiper-theme-color: white;
	}
	.p-swiper-pic {
		width: 25vw;
	}
</style>
<script>
	// import Velocity from "velocity-animate";
	import { Howl } from "howler";

	import vLogo from "../components/floatLogo.vue";
	import vMenu from "../components/menu.vue";
	import vFooter from "../components/footer.vue";
	import items from "../contents/items";

	export default {
		name: "Item",
		data() {
			return {
				swiperOptions: {
					loop: true,
					pagination: {
						el: ".swiper-pagination",
					},
					autoplay: {
						delay: 3000, //4秒切换一次
					},
				},

				options: {
					scrollBar: false,
					scrollOverflow: true,
					licenseKey: "0A5D6727-02894A2F-A632A1DE-E57F2BEB",
				},
				loading: true,
				scrollTop: 0,
				progress: 0,
				seek: "00:00:00",
				total: 0,
				item: {},
				playing: false,
				sound: {},
			};
		},
		methods: {
			clearPage(val, callback) {
				clearInterval(this.interval);
				// console.log(this.sound);
				this.sound.stop();
				this.sound = null;
				this.$refs.fullpage.api.destroy("all");
				// window.removeEventListener("scroll", this.handleScroll);
				callback("ok");
			},
			goto(name) {
				// console.log("goto");
				this.loading = true;
				if (this.$route.name == `/${name}`) {
					//
				} else {
					// this.sound = null;
					this.clearPage({}, () => {});
					this.$router.push(name);
				}
			},
			doPlaySound() {
				let that = this;
				this.sound.play();
				this.playing = true;
				this.total = this.sound.duration();
				this.interval = setInterval(() => {
					updateWidth();
				}, 300);

				function formatDuraton(time) {
					if (time > -1) {
						var hour = Math.floor(time / 3600);
						var min = Math.floor(time / 60) % 60;
						var sec = time % 60;
						if (hour < 10) {
							time = "0" + hour + ":";
						} else {
							time = hour + ":";
						}

						if (min < 10) {
							time += "0";
						}
						time += min + ":";

						if (sec < 10) {
							time += "0";
						}
						time += sec;
					}
					return time;
				}

				function updateWidth() {
					if (that.sound.playing()) {
						that.progress = that.sound.seek() / that.sound.duration();
						that.seek = formatDuraton(Math.floor(that.sound.seek()));
					}
				}
			},
			pause() {
				this.sound.pause();
				this.playing = false;
			},
			start() {
				this.sound.play();
				this.playing = true;
			},
			handleScroll() {
				this.scrollTop =
					window.pageYOffset ||
					document.documentElement.scrollTop ||
					document.body.scrollTop;
			},

			// transformPage() {
			// 	Velocity(this.$refs.header, {
			// 		height: 490,
			// 		duration: 1000,
			// 	});
			// },
			// transformPageReverse() {
			// 	Velocity(this.$refs.header, {
			// 		height: "100%",
			// 		duration: 1000,
			// 	});
			// },
			initItem() {
				// window.addEventListener("scroll", this.handleScroll, false);
				// console.log("init");
				this.item = items[this.$route.params.product];

				this.sound = new Howl({
					src: [this.item.sound],
					autoplay: true,
					loop: true,
				});
				let that = this;
				this.options.onLeave = function (origin, destination, direction) {
					console.log(direction);
					if (direction == "down") {
						// Howler.volume(0.5);
						that.sound.fade(1, 0, 1000);
					}
					if (direction == "up") {
						//restore vol
						// Howler.volume(1);
						that.sound.fade(0, 1, 1000);
					}
				};
				this.$refs.fullpage.init();
				this.doPlaySound();
				this.loading = false;
			},

			next(forward) {
				let list = Object.keys(items);
				let index = this.item.index;

				// console.log(list);
				// console.log(index);
				if (forward == 1) {
					this.goto(index >= list.length - 1 ? list[0] : list[index + 1]);
				} else {
					this.goto(index <= 0 ? list[list.length - 1] : list[index - 1]);
				}
			},
			showDetail() {
				console.log("goto detail");

				this.$refs.fullpage.api.moveSectionDown();
			},
		},
		components: { vLogo, vMenu, vFooter },
		computed: {},
		mounted() {
			this.initItem();
			this.$ba.trackPageview(this.$route.path);
		},

		destroyed() {
			// 离开该页面需要移除这个监听的事件，不然会报错
			// window.removeEventListener("scroll", this.handleScroll);
		},
		watch: {
			$route(to, from) {
				if (to.name == "Item") {
					if (to != from) {
						this.initItem();
					}
				}
			},
		},
	};
</script>
