<!--
 * @Date: 2021-07-15 18:01:05
 * @LastEditors: Woz
 * @LastEditTime: 2021-09-15 17:51:13
 * @FilePath: \groundle_web\src\views\Scent.vue
-->

<template>
	<div class="scent page">
		<vMenu @beforeLeave="clearPage"></vMenu>
		<vLogo></vLogo>
		<div class="holder">
			<div class="icon-holder">
				<div class="icon" @click="goto('/item/leather')">
					<div class="m-pic soil" ref="soil">
						<FramesAnimation :imageList="imgListLeather">
							<!-- slot -->
							<!-- <template #loading>loading</template>
							<template #error>error</template> -->
						</FramesAnimation>
					</div>
				</div>

				<div class="icon" @click="goto('/item/mint')">
					<div class="m-pic soil" ref="mint">
						<FramesAnimation :imageList="imgListMint">
							<!-- slot -->
							<!-- <template #loading>loading</template>
							<template #error>error</template> -->
						</FramesAnimation>
					</div>
				</div>

				<div class="icon" @click="goto('/item/pinksalt')">
					<div class="m-pic soil" ref="pinksalt">
						<FramesAnimation :imageList="imgListSalt">
							<!-- slot -->
							<!-- <template #loading>loading</template>
							<template #error>error</template> -->
						</FramesAnimation>
					</div>
				</div>
				<div class="icon" @click="goto('/item/soil')">
					<div class="m-pic soil" ref="soil">
						<FramesAnimation :imageList="imgListSoil">
							<!-- slot -->
							<!-- <template #loading>loading</template>
							<template #error>error</template> -->
						</FramesAnimation>
					</div>
				</div>

				<div class="icon" @click="goto('/item/olive')">
					<div class="m-pic olive" ref="olive">
						<FramesAnimation :imageList="imgListOlive">
							<!-- slot -->
							<!-- <template #loading>loading</template>
							<template #error>error</template> -->
						</FramesAnimation>
					</div>
				</div>
				<div class="icon" @click="goto('/item/cotton')">
					<div class="m-pic cotton" ref="cotton">
						<FramesAnimation :imageList="imgListCotton">
							<!-- slot -->
							<!-- <template #loading>loading</template>
							<template #error>error</template> -->
						</FramesAnimation>
					</div>
				</div>

				<div class="icon" @click="goto('/item/moss')">
					<div class="m-pic moss" ref="moss">
						<FramesAnimation :imageList="imgListMoss">
							<!-- slot -->
							<!-- <template #loading>loading</template>
							<template #error>error</template> -->
						</FramesAnimation>
					</div>
				</div>
				<div class="icon" @click="goto('/item/deadsea')">
					<div class="m-pic deadsea" ref="deadsea">
						<FramesAnimation :imageList="imgListDeadsea">
							<!-- slot -->
							<!-- <template #loading>loading</template>
							<template #error>error</template> -->
						</FramesAnimation>
					</div>
				</div>
				<div class="icon" @click="goto('/item/mehmeh')">
					<div class="m-pic mehmeh" ref="mehmeh">
						<FramesAnimation :imageList="imgListMeh">
							<!-- slot -->
							<!-- <template #loading>loading</template>
							<template #error>error</template> -->
						</FramesAnimation>
					</div>
					<img src="../assets/limit.png" class="limit" />
				</div>
			</div>
		</div>
	</div>
</template>
<style scoped>
	.limit {
		position: absolute;
		z-index: 10;
		bottom: 33px;
		left: 250px;
		height: auto !important;
		width: 183px !important;
	}
	.scent {
		background: url("../assets/bg_scent.jpg");
		background-size: cover;
		background-position: center center;
		display: flex;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		align-content: center;
		flex-wrap: nowrap;
	}
	.icon {
		/* background: yellow; */
		margin: 20px;
		/* overflow: hidden; */
		/* border-radius: 40%; */

		position: relative;
		width: 260px;
		height: 260px;
	}

	.m-pic img {
		width: 100% !important;
		height: 100%;
	}

	.cotton img {
		width: 100%；;
	}
	.icon .m-pic {
		width: 100%;
		height: 100%;
	}
	.icon-holder {
		display: flex;

		/* justify-content: space-between; */
		width: 900px;
		margin: auto;
		flex-wrap: wrap;
		align-items: center;

		justify-content: flex-start;
	}
	.blur {
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		left: -100%;
	}
	.m-pic:hover {
		transform: scale(1.05);
	}
	.m-pic:hover .blur {
		transform: translateX(180%);
		transition: 1s all ease-out;
		opacity: 0.8;
	}
	.m-pic {
		transition: 0.2s all ease-out;
	}
</style>
<script>
	import vLogo from "../components/floatLogo.vue";
	import vMenu from "../components/menu.vue";
	import FramesAnimation from "../components/animation.vue";
	export default {
		name: "Scent",
		data() {
			return {
				isReady: false,
				sprite: {},

				imgListLeather: [
					require("@/assets/coin/leather/00.png"),
					require("@/assets/coin/leather/01.png"),
					require("@/assets/coin/leather/02.png"),
					require("@/assets/coin/leather/03.png"),
					require("@/assets/coin/leather/04.png"),
					require("@/assets/coin/leather/05.png"),
					require("@/assets/coin/leather/06.png"),
					require("@/assets/coin/leather/07.png"),
					require("@/assets/coin/leather/08.png"),
					require("@/assets/coin/leather/09.png"),
					require("@/assets/coin/leather/10.png"),
					require("@/assets/coin/leather/11.png"),
					require("@/assets/coin/leather/12.png"),
					require("@/assets/coin/leather/13.png"),
					require("@/assets/coin/leather/14.png"),
					require("@/assets/coin/leather/15.png"),
					require("@/assets/coin/leather/16.png"),
					require("@/assets/coin/leather/17.png"),
					require("@/assets/coin/leather/18.png"),
					require("@/assets/coin/leather/19.png"),
					require("@/assets/coin/leather/20.png"),
					require("@/assets/coin/leather/21.png"),
					require("@/assets/coin/leather/22.png"),
					require("@/assets/coin/leather/23.png"),
					require("@/assets/coin/leather/24.png"),
				],
				imgListCotton: [
					require("@/assets/coin/cotton/00.png"),
					require("@/assets/coin/cotton/01.png"),
					require("@/assets/coin/cotton/02.png"),
					require("@/assets/coin/cotton/03.png"),
					require("@/assets/coin/cotton/04.png"),
					require("@/assets/coin/cotton/05.png"),
					require("@/assets/coin/cotton/06.png"),
					require("@/assets/coin/cotton/07.png"),
					require("@/assets/coin/cotton/08.png"),
					require("@/assets/coin/cotton/09.png"),
					require("@/assets/coin/cotton/10.png"),
					require("@/assets/coin/cotton/11.png"),
					require("@/assets/coin/cotton/12.png"),
					require("@/assets/coin/cotton/13.png"),
					require("@/assets/coin/cotton/14.png"),
					require("@/assets/coin/cotton/15.png"),
					require("@/assets/coin/cotton/16.png"),
					require("@/assets/coin/cotton/17.png"),
					require("@/assets/coin/cotton/18.png"),
					require("@/assets/coin/cotton/19.png"),
					require("@/assets/coin/cotton/20.png"),
					require("@/assets/coin/cotton/21.png"),
					require("@/assets/coin/cotton/22.png"),
					require("@/assets/coin/cotton/23.png"),
					require("@/assets/coin/cotton/24.png"),
				],
				imgListMoss: [
					require("@/assets/coin/moss/00.png"),
					require("@/assets/coin/moss/01.png"),
					require("@/assets/coin/moss/02.png"),
					require("@/assets/coin/moss/03.png"),
					require("@/assets/coin/moss/04.png"),
					require("@/assets/coin/moss/05.png"),
					require("@/assets/coin/moss/06.png"),
					require("@/assets/coin/moss/07.png"),
					require("@/assets/coin/moss/08.png"),
					require("@/assets/coin/moss/09.png"),
					require("@/assets/coin/moss/10.png"),
					require("@/assets/coin/moss/11.png"),
					require("@/assets/coin/moss/12.png"),
					require("@/assets/coin/moss/13.png"),
					require("@/assets/coin/moss/14.png"),
					require("@/assets/coin/moss/15.png"),
					require("@/assets/coin/moss/16.png"),
					require("@/assets/coin/moss/17.png"),
					require("@/assets/coin/moss/18.png"),
					require("@/assets/coin/moss/19.png"),
					require("@/assets/coin/moss/20.png"),
					require("@/assets/coin/moss/21.png"),
					require("@/assets/coin/moss/22.png"),
					require("@/assets/coin/moss/23.png"),
					require("@/assets/coin/moss/24.png"),
				],
				imgListDeadsea: [
					require("@/assets/coin/deadsea/00.png"),
					require("@/assets/coin/deadsea/01.png"),
					require("@/assets/coin/deadsea/02.png"),
					require("@/assets/coin/deadsea/03.png"),
					require("@/assets/coin/deadsea/04.png"),
					require("@/assets/coin/deadsea/05.png"),
					require("@/assets/coin/deadsea/06.png"),
					require("@/assets/coin/deadsea/07.png"),
					require("@/assets/coin/deadsea/08.png"),
					require("@/assets/coin/deadsea/09.png"),
					require("@/assets/coin/deadsea/10.png"),
					require("@/assets/coin/deadsea/11.png"),
					require("@/assets/coin/deadsea/12.png"),
					require("@/assets/coin/deadsea/13.png"),
					require("@/assets/coin/deadsea/14.png"),
					require("@/assets/coin/deadsea/15.png"),
					require("@/assets/coin/deadsea/16.png"),
					require("@/assets/coin/deadsea/17.png"),
					require("@/assets/coin/deadsea/18.png"),
					require("@/assets/coin/deadsea/19.png"),
					require("@/assets/coin/deadsea/20.png"),
					require("@/assets/coin/deadsea/21.png"),
					require("@/assets/coin/deadsea/22.png"),
					require("@/assets/coin/deadsea/23.png"),
					require("@/assets/coin/deadsea/24.png"),
				],
				imgListOlive: [
					require("@/assets/coin/olive/00.png"),
					require("@/assets/coin/olive/01.png"),
					require("@/assets/coin/olive/02.png"),
					require("@/assets/coin/olive/03.png"),
					require("@/assets/coin/olive/04.png"),
					require("@/assets/coin/olive/05.png"),
					require("@/assets/coin/olive/06.png"),
					require("@/assets/coin/olive/07.png"),
					require("@/assets/coin/olive/08.png"),
					require("@/assets/coin/olive/09.png"),
					require("@/assets/coin/olive/10.png"),
					require("@/assets/coin/olive/11.png"),
					require("@/assets/coin/olive/12.png"),
					require("@/assets/coin/olive/13.png"),
					require("@/assets/coin/olive/14.png"),
					require("@/assets/coin/olive/15.png"),
					require("@/assets/coin/olive/16.png"),
					require("@/assets/coin/olive/17.png"),
					require("@/assets/coin/olive/18.png"),
					require("@/assets/coin/olive/19.png"),
					require("@/assets/coin/olive/20.png"),
					require("@/assets/coin/olive/21.png"),
					require("@/assets/coin/olive/22.png"),
					require("@/assets/coin/olive/23.png"),
					require("@/assets/coin/olive/24.png"),
				],
				imgListSoil: [
					require("@/assets/coin/soil/00.png"),
					require("@/assets/coin/soil/01.png"),
					require("@/assets/coin/soil/02.png"),
					require("@/assets/coin/soil/03.png"),
					require("@/assets/coin/soil/04.png"),
					require("@/assets/coin/soil/05.png"),
					require("@/assets/coin/soil/06.png"),
					require("@/assets/coin/soil/07.png"),
					require("@/assets/coin/soil/08.png"),
					require("@/assets/coin/soil/09.png"),
					require("@/assets/coin/soil/10.png"),
					require("@/assets/coin/soil/11.png"),
					require("@/assets/coin/soil/12.png"),
					require("@/assets/coin/soil/13.png"),
					require("@/assets/coin/soil/14.png"),
					require("@/assets/coin/soil/15.png"),
					require("@/assets/coin/soil/16.png"),
					require("@/assets/coin/soil/17.png"),
					require("@/assets/coin/soil/18.png"),
					require("@/assets/coin/soil/19.png"),
					require("@/assets/coin/soil/20.png"),
					require("@/assets/coin/soil/21.png"),
					require("@/assets/coin/soil/22.png"),
					require("@/assets/coin/soil/23.png"),
					require("@/assets/coin/soil/24.png"),
				],

				imgListMeh: [
					require("@/assets/coin/mehmeh/00.png"),
					require("@/assets/coin/mehmeh/01.png"),
					require("@/assets/coin/mehmeh/02.png"),
					require("@/assets/coin/mehmeh/03.png"),
					require("@/assets/coin/mehmeh/04.png"),
					require("@/assets/coin/mehmeh/05.png"),
					require("@/assets/coin/mehmeh/06.png"),
					require("@/assets/coin/mehmeh/07.png"),
					require("@/assets/coin/mehmeh/08.png"),
					require("@/assets/coin/mehmeh/09.png"),
					require("@/assets/coin/mehmeh/10.png"),
					require("@/assets/coin/mehmeh/11.png"),
					require("@/assets/coin/mehmeh/12.png"),
					require("@/assets/coin/mehmeh/13.png"),
					require("@/assets/coin/mehmeh/14.png"),
					require("@/assets/coin/mehmeh/15.png"),
					require("@/assets/coin/mehmeh/16.png"),
					require("@/assets/coin/mehmeh/17.png"),
					require("@/assets/coin/mehmeh/18.png"),
					require("@/assets/coin/mehmeh/19.png"),
					require("@/assets/coin/mehmeh/20.png"),
					require("@/assets/coin/mehmeh/21.png"),
					require("@/assets/coin/mehmeh/22.png"),
					require("@/assets/coin/mehmeh/23.png"),
					require("@/assets/coin/mehmeh/24.png"),
				],
				imgListSalt: [
					require("@/assets/coin/pinksalt/00.png"),
					require("@/assets/coin/pinksalt/01.png"),
					require("@/assets/coin/pinksalt/02.png"),
					require("@/assets/coin/pinksalt/03.png"),
					require("@/assets/coin/pinksalt/04.png"),
					require("@/assets/coin/pinksalt/05.png"),
					require("@/assets/coin/pinksalt/06.png"),
					require("@/assets/coin/pinksalt/07.png"),
					require("@/assets/coin/pinksalt/08.png"),
					require("@/assets/coin/pinksalt/09.png"),
					require("@/assets/coin/pinksalt/10.png"),
					require("@/assets/coin/pinksalt/11.png"),
					require("@/assets/coin/pinksalt/12.png"),
					require("@/assets/coin/pinksalt/13.png"),
					require("@/assets/coin/pinksalt/14.png"),
					require("@/assets/coin/pinksalt/15.png"),
					require("@/assets/coin/pinksalt/16.png"),
					require("@/assets/coin/pinksalt/17.png"),
					require("@/assets/coin/pinksalt/18.png"),
					require("@/assets/coin/pinksalt/19.png"),
					require("@/assets/coin/pinksalt/20.png"),
					require("@/assets/coin/pinksalt/21.png"),
					require("@/assets/coin/pinksalt/22.png"),
					require("@/assets/coin/pinksalt/23.png"),
					require("@/assets/coin/pinksalt/24.png"),
				],
				imgListMint: [
					require("@/assets/coin/mint/00.png"),
					require("@/assets/coin/mint/01.png"),
					require("@/assets/coin/mint/02.png"),
					require("@/assets/coin/mint/03.png"),
					require("@/assets/coin/mint/04.png"),
					require("@/assets/coin/mint/05.png"),
					require("@/assets/coin/mint/06.png"),
					require("@/assets/coin/mint/07.png"),
					require("@/assets/coin/mint/08.png"),
					require("@/assets/coin/mint/09.png"),
					require("@/assets/coin/mint/10.png"),
					require("@/assets/coin/mint/11.png"),
					require("@/assets/coin/mint/12.png"),
					require("@/assets/coin/mint/13.png"),
					require("@/assets/coin/mint/14.png"),
					require("@/assets/coin/mint/15.png"),
					require("@/assets/coin/mint/16.png"),
					require("@/assets/coin/mint/17.png"),
					require("@/assets/coin/mint/18.png"),
					require("@/assets/coin/mint/19.png"),
					require("@/assets/coin/mint/20.png"),
					require("@/assets/coin/mint/21.png"),
					require("@/assets/coin/mint/22.png"),
					require("@/assets/coin/mint/23.png"),
					require("@/assets/coin/mint/24.png"),
				],
			};
		},
		methods: {
			clearPage(val, callback) {
				callback("ok");
			},
			goto(name) {
				console.log(name);
				if (this.$route.name == `/${name}`) {
					//
				} else {
					this.$router.push(name);
				}
			},
		},
		mounted() {
			this.$ba.trackPageview(this.$route.path);
		},
		components: { vLogo, vMenu, FramesAnimation },
		computed: {},
	};
</script>
